const textStyles = {
  h1: {
    fontFamily: 'heading',
    fontWeight: 'bold',
    letterSpacing: 'tight',
    fontSize: {
      base: '8xl',
      sm: '11xl',
      md: '16xl',
      lg: '19xl',
      xl: '20xl',
      xxl: '21xl',
    },
    lineHeight: {
      base: '7xl',
      sm: '12xl',
      md: '18xl',
      lg: '22xl',
      xl: '23xl',
      xxl: '25xl',
    },
  },
  h2: {
    fontFamily: 'heading',
    fontWeight: 'bold',
    letterSpacing: 'tight',
    fontSize: {
      base: '6xl',
      sm: '9xl',
      md: '13xl',
      lg: '15xl',
      xl: '18xl',
      xxl: '20xl',
    },
    lineHeight: {
      base: '4xl',
      sm: '9xl',
      md: '14xl',
      lg: '17xl',
      xl: '20xl',
      xxl: '23xl',
    },
  },
  h3: {
    fontFamily: 'heading',
    fontWeight: 'bold',
    letterSpacing: 'tight',
    fontSize: {
      base: '4xl',
      sm: '7xl',
      md: '11xl',
      lg: '12xl',
      xl: '14xl',
      xxl: '17xl',
    },
    lineHeight: {
      base: '3xl',
      sm: '6xl',
      md: '12xl',
      lg: '13xl',
      xl: '15xl',
      xxl: '19xl',
    },
  },
  h4: {
    fontFamily: 'heading',
    fontWeight: 'bold',
    letterSpacing: 'normal',
    fontSize: {
      base: '2xl',
      sm: '4xl',
      md: '6xl',
      xl: '7xl',
      xxl: '10xl',
    },
    lineHeight: {
      base: 'lg',
      sm: '3xl',
      md: '5xl',
      xl: '6xl',
      xxl: '10xl',
    },
  },
  h5: {
    fontFamily: 'heading',
    fontWeight: 'bold',
    letterSpacing: 'normal',
    fontSize: {
      base: 'lg',
      md: '3xl',
      xl: '5xl',
      xxl: '8xl',
    },
    lineHeight: {
      base: 'sm',
      md: 'xl',
      xl: '4xl',
      xxl: '7xl',
    },
  },
  bodyLg: {
    fontFamily: 'body',
    fontWeight: 'regular',
    letterSpacing: 'normal',
    fontSize: {
      base: 'md',
      sm: 'lg',
      md: '3xl',
    },
    lineHeight: {
      base: 'xl',
      sm: 'xl',
      md: '8xl',
    },
  },
  bodyMd: {
    fontFamily: 'body',
    fontWeight: 'regular',
    letterSpacing: 'normal',
    fontSize: {
      base: 'md',
      sm: 'lg',
      md: 'xl',
    },
    lineHeight: {
      base: '2xl',
      sm: '2xl',
      md: '4xl',
    },
  },
  bodySm: {
    fontFamily: 'body',
    fontWeight: 'regular',
    letterSpacing: 'normal',
    fontSize: {
      base: 'sm',
      md: 'md',
    },
    lineHeight: {
      base: 'sm',
      md: 'xl',
    },
  },
  bodySm2: {
    fontFamily: 'body',
    fontWeight: 'regular',
    letterSpacing: 'normal',
    fontSize: {
      base: 'sm',
      md: 'md',
    },
    lineHeight: {
      base: 'xs',
      md: 'xl',
    },
  },
  bodyXs: {
    fontFamily: 'body',
    fontWeight: 'regular',
    letterSpacing: 'normal',
    fontSize: 'xs',
    lineHeight: 'xs',
  },
  captionXl: {
    fontFamily: 'body',
    fontWeight: 'regular',
    letterSpacing: 'normal',
    fontSize: {
      base: 'md',
      md: 'lg',
      lg: 'xl',
    },
    lineHeight: {
      base: 'lg',
      md: '2xl',
      lg: '4xl',
    },
  },
  captionLg: {
    fontFamily: 'body',
    fontWeight: 'regular',
    letterSpacing: 'normal',
    fontSize: 'lg',
    lineHeight: '2xl',
  },
  captionMd: {
    fontFamily: 'body',
    fontWeight: 'regular',
    letterSpacing: 'normal',
    fontSize: 'sm',
    lineHeight: 'sm',
  },
  captionSm: {
    fontFamily: 'body',
    fontWeight: 'regular',
    letterSpacing: 'wide',
    fontSize: 'xs',
    lineHeight: 'xs',
  },
  linkLg: {
    fontSize: 'lg',
    fontWeight: 'regular',
    letterSpacing: 'normal',
    lineHeight: 'md',
  },
  linkXl: {
    fontSize: '3xl',
    fontWeight: 'regular',
    letterSpacing: 'normal',
    lineHeight: '6xl',
  },
  link2xl: {
    fontSize: '7xl',
    fontWeight: 'regular',
    letterSpacing: 'normal',
    lineHeight: '11xl',
  },
  build: {
    item: {
      title: {
        fontFamily: 'heading',
        fontWeight: 'regular',
        letterSpacing: 'normal',
        fontSize: {
          base: '22px',
          sm: '26px',
          lg: '30px',
          xl: '32px',
        },
        lineHeight: {
          base: '24px',
          sm: '30px',
          md: '36px',
          lg: '34px',
          xl: '36px',
        },
      },
    },
  },
  launchBlockchain: {
    text: {
      fontWeight: 'regular',
      letterSpacing: 'normal',
      fontFamily: 'body',
      fontSize: {
        base: 'md',
        sm: 'lg',
        md: 'xl',
      },
      lineHeight: {
        base: 'xl',
        sm: '2xl',
        md: '4xl',
      },
    },
    item: {
      title: {
        fontWeight: 'regular',
        letterSpacing: 'normal',
        fontFamily: 'heading',
        fontSize: {
          base: '2xl',
          sm: '4xl',
          md: '7xl',
          lg: '6xl',
          xl: '7xl',
          xxl: '10xl',
        },
        lineHeight: {
          base: 'lg',
          sm: '3xl',
          md: '6xl',
          lg: '5xl',
          xl: '6xl',
          xxl: '10xl',
        },
      },
      text: {
        fontWeight: 'regular',
        letterSpacing: 'normal',
        fontFamily: 'body',
        fontSize: {
          base: 'md',
          sm: 'lg',
          md: 'xl',
        },
        lineHeight: {
          base: 'xl',
          sm: '2xl',
          md: '4xl',
        },
      },
    },
  },
  infrastructure: {
    item: {
      title: {
        fontFamily: 'heading',
        fontWeight: 'bold',
        letterSpacing: 'normal',
        fontSize: {
          base: 'lg',
          md: '3xl',
          xl: '5xl',
        },
        lineHeight: {
          base: 'sm',
          md: 'xl',
          xl: '4xl',
        },
      },
    },
  },
  useCases: {
    title: {
      fontFamily: 'heading',
      fontWeight: 'bold',
      letterSpacing: 'tight',
      fontSize: {
        base: '4xl',
        sm: '7xl',
        md: '11xl',
        lg: '12xl',
        xl: '64px',
        xxl: '17xl',
      },
      lineHeight: {
        base: '3xl',
        sm: '6xl',
        md: '12xl',
        lg: '13xl',
        xl: '15xl',
        xxl: '19xl',
      },
    },
  },
  copyright: {
    fontFamily: 'body',
    fontWeight: 'regular',
    letterSpacing: 'wide',
    fontSize: {
      base: 'xxs',
      sm: 'xs',
    },
    lineHeight: {
      base: 'xxs',
      sm: 'xs',
    },
  },
  timeToBuild: {
    title: {
      fontFamily: 'heading',
      fontWeight: 'bold',
      letterSpacing: '-0.03em',
      fontSize: {
        base: '82px',
        sm: '120px',
        md: '146px',
        lg: '130px',
        xl: '184px',
        xxl: '250px',
      },
      lineHeight: '110%',
    },
  },
};

export type TextStyles = typeof textStyles;

export default textStyles;
