const typography = {
  fonts: {
    body: `'JetBrains Mono', monospace`,
    heading: `'Helvetica', sans-serif`,
  },
  fontSizes: {
    xxs: '10px',
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '20px',
    '2xl': '22px',
    '3xl': '24px',
    '4xl': '26px',
    '5xl': '28px',
    '6xl': '30px',
    '7xl': '32px',
    '8xl': '34px',
    '9xl': '40px',
    '10xl': '42px',
    '11xl': '46px',
    '12xl': '48px',
    '13xl': '62px',
    '14xl': '66px',
    '15xl': '68px',
    '16xl': '72px',
    '17xl': '78px',
    '18xl': '80px',
    '19xl': '88px',
    '20xl': '96px',
    '21xl': '128px',
  },
  lineHeights: {
    xxs: '14px',
    xs: '18px',
    sm: '20px',
    md: '22px',
    lg: '24px',
    xl: '26px',
    '2xl': '28px',
    '3xl': '30px',
    '4xl': '32px',
    '5xl': '34px',
    '6xl': '36px',
    '7xl': '38px',
    '8xl': '40px',
    '9xl': '44px',
    '10xl': '46px',
    '11xl': '48px',
    '12xl': '52px',
    '13xl': '54px',
    '14xl': '68px',
    '15xl': '70px',
    '16xl': '72px',
    '17xl': '76px',
    '18xl': '80px',
    '19xl': '86px',
    '20xl': '88px',
    '21xl': '96px',
    '22xl': '98px',
    '23xl': '106px',
    '24xl': '128px',
    '25xl': '142px',
  },
  fontWeights: {
    regular: 400,
    bold: 600,
  },
  letterSpacings: {
    tight: '-0.02em',
    normal: '0',
    wide: '0.04em',
  },
};

export default typography;
