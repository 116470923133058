import { defineStyleConfig } from '@chakra-ui/react';

const LaunchBlockchainAccordionItemStyles = defineStyleConfig({
  baseStyle: {
    button: {
      px: '0',
      py: { base: '24px', sm: '30px', xxl: '45px' },
      transition: '.3s ease',
      color: 'whiteAlpha.20',
      borderBottom: { md: '1px solid' },
      borderColor: { md: 'whiteAlpha.10' },
      _hover: {
        bg: 'none',
      },
      span: {
        color: 'whiteAlpha.10',
        transition: '.3s ease',
      },
      p: {
        h: '0',
        overflow: 'hidden',
        opacity: '0',
        visibility: 'hidden',
        transition: '.3s ease',
      },
      _expanded: {
        color: 'foreground.white',
        borderColor: { md: 'background.white' },
        p: {
          h: 'auto',
          opacity: '1',
          visibility: 'visible',
          mt: {
            base: '16px',
            md: '24px',
          },
        },
        span: {
          color: 'accent.green',
        },
        '+ .chakra-collapse .chakra-accordion__panel': {
          borderColor: 'background.white',
        },
      },
    },
    '.chakra-collapse': {
      overflow: 'unset!important',
    },
    panel: {
      borderBottom: { base: '1px solid', md: 'none' },
      borderColor: 'whiteAlpha.10',
      transition: '.3s ease',
    },
    container: {
      borderTop: '0',
    },
    '.chakra-accordion__item': {
      borderBottom: { base: '1px solid', md: 'none' },
      borderColor: 'whiteAlpha.10',
    },
  },
  sizes: {},
  variants: {},
  defaultProps: {},
});

export default LaunchBlockchainAccordionItemStyles;
