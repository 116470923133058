import { defineStyleConfig } from '@chakra-ui/react';

const LinkStyles = defineStyleConfig({
  baseStyle: {
    _hover: {
      textDecoration: 'none',
    },
    span: {
      position: 'relative',
      display: 'inline-block',
      _after: {
        content: '""',
        transition: '.3s ease',
        position: 'absolute',
        height: '1px',
        bottom: '0',
        bgColor: 'currentColor',
      },
    },
  },
  sizes: {
    sm: {
      textStyle: 'captionSm',
    },
    md: {
      textStyle: 'captionMd',
    },
    lg: {
      textStyle: 'linkLg',
    },
    xl: {
      textStyle: 'linkXl',
    },
    '2xl': {
      textStyle: 'link2xl',
    },
  },
  variants: {
    default: {
      span: {
        _after: {
          right: '0',
          width: '0',
        },
      },
      _hover: {
        span: {
          _after: {
            right: 'auto',
            left: '0',
            width: '100%',
          },
        },
      },
    },
    underline: {
      span: {
        _after: {
          left: '0',
          width: '100%',
        },
      },
      _hover: {
        span: {
          _after: {
            left: 'auto',
            right: '0',
            width: '0',
          },
        },
      },
    },
  },
  defaultProps: {
    variant: 'default',
  },
});

export default LinkStyles;
