import { defineStyleConfig } from '@chakra-ui/react';

const UseCasesStyles = defineStyleConfig({
  baseStyle: {
    '.chakra-tabs__tablist': {
      borderBottom: '0',
      overflowX: { base: 'auto' },
      px: { base: '16px', sm: '24px', md: '44px', lg: '0' },
      pr: { lg: '60px' },
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      '::-webkit-scrollbar': {
        width: '0',
        height: '0',
      },
    },
    '.chakra-tabs__tab': {
      py: '2px',
      px: '0',
      textStyle: 'captionMd',
      borderBottom: '0',
      textTransform: 'uppercase',
      flexShrink: '0',
      _notLast: {
        mr: '24px',
      },
    },
    '.chakra-tabs__tab-panel': {
      p: '0',
      '> div': {
        px: { base: '16px', sm: '24px', md: '44px', xl: '0' },
        pr: { lg: '0' },
        overflowX: { base: 'auto', md: 'unset' },
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '::-webkit-scrollbar': {
          width: '0',
          height: '0',
        },
        scrollSnapStop: 'always',
        scrollSnapType: 'x mandatory',
      },
    },
  },
});

export default UseCasesStyles;
