const spacing = {
  space: {
    gap: {
      xs: '8px',
      md: '12px',
      lg: '16px',
      xl: '20px',
      '2xl': '24px',
      '3xl': '32px',
      '4xl': '40px',
      '5xl': '48px',
      '6xl': '50px',
      '7xl': '56px',
      '8xl': '64px',
      '9xl': '72px',
      '10xl': '80px',
    },
    container: {
      xs: '16px',
      sm: '24px',
      md: '44px',
      xl: '60px',
    },
    section: {
      xs: '48px',
      sm: '80px',
      md: '104px',
      lg: '120px',
      xl: '136px',
    },
    header: {
      xs: '18px',
      md: '32px',
    },
    footer: {
      xs: '16px',
      md: '24px',
      xl: '32px',
    },
    infrastructure: {
      xs: '48px',
      sm: '80px',
      md: '104px',
      lg: '120px',
    },
    cases: {
      left: {
        xs: '32px',
        sm: '48px',
        md: '64px',
        lg: '80px',
      },
      right: {
        xs: '24px',
        sm: '32px',
        md: '40px',
        lg: '46px',
        xl: '40px',
        xxl: '155px',
      },
    },
    button: {
      gap: {
        xs: '12px',
        md: '15px',
      },
      padding: {
        sm: '20px',
        lg: '32px',
      },
    },
  },
};

export default spacing;
