const colors = {
  foreground: {
    white: '#FFFFFF',
    black: '#000000',
    gray: '#6F6F6F',
    lightgray: '#969696',
    green: '#7DFFAF',
  },
  background: {
    black: '#000000',
    white: '#FFFFFF',
    red: '#FF2021',
    grey: '#F1F1F1',
  },
  accent: {
    red: '#FF2021',
    purple: '#A66CFF',
    pink: '#EE82EE',
    green: '#7DFFAF',
    blue: '#87CEEB',
  },
  whiteAlpha: {
    10: 'rgba(255, 255, 255, 0.1)',
    20: 'rgba(255, 255, 255, 0.2)',
    50: 'rgba(255, 255, 255, 0.5)',
  },
  accentAlpha: {
    red: 'rgba(255, 32, 33, 0.2)',
    purple: 'rgba(166, 108, 255, 0.2)',
    pink: 'rgba(238, 130, 238, 0.2)',
    green: 'rgba(125, 255, 175, 0.2)',
    blue: 'rgba(135, 206, 235, 0.2)',
  },
};

export default colors;
