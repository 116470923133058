import { defineStyleConfig } from '@chakra-ui/react';

const ModalStyles = defineStyleConfig({
  baseStyle: {
    body: {
      p: 0,
    },
    dialog: {
      bg: 'background.white',
      borderRadius: 0,
      p: { base: '56px 24px 24px', sm: '40px', md: '56px' },
    },
  },
});

export default ModalStyles;
