import { defineStyleConfig } from '@chakra-ui/react';

const InputStyles = defineStyleConfig({
  baseStyle: {
    field: {
      color: 'background.black',
      borderRadius: 0,
      textStyle: 'bodySm',
      bg: 'background.grey',
      p: '15px 16px',
    },
  },
  sizes: {
    sm: {
      field: {
        h: '50px',
        borderRadius: 0,
      },
    },
    md: {
      field: {
        h: '56px',
        borderRadius: 0,
      },
    },
  },
  variants: {
    base: {
      field: {
        border: 'none',
      },
    },
  },
  defaultProps: {
    size: 'sm',
    variant: 'base',
  },
});

export default InputStyles;
