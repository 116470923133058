import { defineStyleConfig } from '@chakra-ui/react';

const ButtonStyle = defineStyleConfig({
  baseStyle: {
    borderRadius: 0,
    cursor: 'pointer',
    textTransform: 'uppercase',
    transition: '.3s ease-in',
    fontWeight: 'regular',
    border: '1px solid transparent',
  },
  sizes: {
    sm: {
      pl: 'button.padding.sm',
      pr: 'button.padding.sm',
      h: '40px',
      fontSize: {
        base: 'md',
        sm: 'lg',
        md: 'sm',
      },
      lineHeight: {
        base: 'lg',
        sm: '2xl',
        md: 'sm',
      },
    },
    md: {
      pl: 'button.padding.lg',
      pr: 'button.padding.lg',
      h: '48px',
      fontSize: {
        base: 'md',
        sm: 'lg',
      },
      lineHeight: {
        base: 'lg',
        sm: '2xl',
      },
    },
    lg: {
      pl: 'button.padding.lg',
      pr: 'button.padding.lg',
      h: '56px',
      fontSize: {
        base: 'md',
        sm: 'lg',
      },
      lineHeight: {
        base: 'lg',
        sm: '2xl',
      },
    },
  },
  variants: {
    solidBlack: {
      bg: 'background.black',
      color: 'foreground.white',
      _hover: {
        bg: 'none',
        color: 'foreground.black',
        borderColor: 'background.black',
      },
    },
    solidWhite: {
      bg: 'background.white',
      color: 'foreground.black',
      _hover: {
        bg: 'none',
        color: 'foreground.white',
        borderColor: 'background.white',
      },
    },
    outlineBlack: {
      borderColor: 'background.black',
      color: 'foreground.black',
      _hover: {
        bg: 'background.black',
        color: 'foreground.white',
      },
    },
    outlineWhite: {
      borderColor: 'background.white',
      color: 'foreground.white',
      _hover: {
        bg: 'background.white',
        color: 'foreground.black',
      },
    },
  },
  defaultProps: {
    size: 'sm',
  },
});

export default ButtonStyle;
