import { defineStyleConfig } from '@chakra-ui/react';

const FooterStyles = defineStyleConfig({
  baseStyle: {
    '.footer-menu': {
      columnCount: { base: 3, md: 'initial' },
      columnGap: { md: '20px' },
      display: { md: 'flex' },
    },
  },
  variants: {
    black: {
      bgColor: 'background.black',
      color: 'foreground.white',
    },
    red: {
      color: 'foreground.black',
      bgColor: 'accent.red',
    },
  },
  defaultProps: {
    variant: 'red',
  },
});

export default FooterStyles;
