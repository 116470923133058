import { defineStyleConfig } from '@chakra-ui/react';

const ContainerStyles = defineStyleConfig({
  baseStyle: {
    maxW: {
      base: 'container.md',
      lg: 'container.lg',
      xl: 'container.xl',
      xxl: 'container.xxl',
    },
    px: {
      base: 'container.xs',
      sm: 'container.sm',
      md: 'container.md',
      xl: 'container.xl',
    },
  },
});

export default ContainerStyles;
