import { defineStyleConfig } from '@chakra-ui/react';

const HeaderStyles = defineStyleConfig({
  baseStyle: {
    '.menu': {
      display: { lg: 'flex' },
      mr: { lg: '31px', xl: '0' },
      mt: { xxl: '3px' },
      position: { xl: 'absolute' },
      top: { xl: '50%' },
      left: { xl: '50%' },
      transform: { xl: 'translate(-50%, -50%)' },
    },
    '.menu-list': {
      pos: 'relative',
      fontSize: { base: 'xl', sm: '3xl', md: '7xl', lg: 'sm' },
      fontWeight: 'regular',
      letterSpacing: 'normal',
      lineHeight: { base: '3xl', sm: '6xl', md: '11xl', lg: 'xl' },
      color: 'currentColor',
      textTransform: 'uppercase',
    },
    '.mobileMenu': {
      pos: { base: 'fixed', lg: 'relative', xl: 'initial' },
      top: 0,
      left: 0,
      right: 0,
      p: { base: '106px 0 16px', sm: '128px 0 16px', md: '240px 0 32px', lg: '0' },
      h: '100%',
      bgColor: { base: 'background.black', lg: 'transparent' },
    },
    '.desktopButton': {
      minWidth: 'max-content',
      alignItems: 'center',
      ml: 'auto',
      gap: 'gap.lg',
      display: { base: 'none', md: 'flex', lg: 'none' },
      position: 'relative',
      zIndex: 2,
    },
    '.mobileButton': {
      mt: { base: '110px', sm: '172px', lg: '0' },
      minWidth: 'max-content',
      alignItems: { md: 'center' },
      flexDirection: { base: 'column', md: 'row', lg: 'row' },
      gap: { base: 'gap.md', sm: 'gap.lg', lg: 'gap.md' },
      display: { base: 'flex', md: 'none', lg: 'flex' },
    },
  },
});

export default HeaderStyles;
